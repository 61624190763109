let lang = document.querySelector('#language')
let langIcon = document.querySelector('#lang-icon')
let otherLang = document.querySelector('#other-lang')
let currentLang = document.querySelector('#current-lang')


lang.addEventListener('click', () => {
    langIcon.classList.toggle('reverce')
    if (lang.classList.value.split(' ').find(el => el === 'open-lang')) {
        lang.classList.remove('open-lang')
        // setTimeout(() => {
        //     lang.classList.remove('open-beautiful') 
        // },500)
        return
    }
    lang.classList.add('open-lang')
    // lang.classList.add('open-beautiful')
})

